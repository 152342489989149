<template>
  <div>
    <van-nav-bar :title="$route.meta.name" left-arrow @click-left="$router.back()"/>
    <page-container
        ref="pageContainer"
        class="nav-page-container"
    >
      <!-- 下拉刷新 -->
      <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
          style="position: relative;z-index: 1"
      >
        <template #loading>
          {{ pagination.pageNum < 2 ? '' : '加载中...' }}
        </template>

        <div class="total-integral">
          {{ point }}
        </div>


        <div class="card">

          <affix-item
              style="position: relative;z-index: 10"
              :target="() => $refs.pageContainer.$el"
              @change="flag => this.affixIsTop = flag"
          >
            <div class="type-select-container">
              <van-tag
                  v-for="type in typeList"
                  size="large"
                  :type="searchForm.type === type.id ? 'primary' : ''"
                  :color="searchForm.type !== type.id ? '#f6f6f6' : ''"
                  :text-color="searchForm.type !== type.id ? '#999' : ''"
                  @click="tagClick(type.id)"
              >
                {{ type.name }}
              </van-tag>
            </div>
          </affix-item>


          <!-- 更多列表加载 -->
          <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
          >
            <div
                v-for="item of dataList"
                class="integral-flow-item"
            >
              <section>
                <span>{{ item.remark }}</span>
                <div>{{ item.type == 1 ? '+' : '-' }}{{ item.integral }}</div>
              </section>
              <p>{{ item.createTime }}</p>
            </div>
          </van-list>

        </div>

      </van-pull-refresh>

    </page-container>
  </div>
</template>

<script>
import { getUserIntegral, getUserIntegralFlowPage } from '@/api/integralGoods';
import AffixItem from '@/components/Affix';

export default {
  name: 'integralFlow',
  components: { AffixItem },
  data() {
    return {
      searchForm: {
        type: '',
      },

      refreshing: false,
      loading: false,
      finished: false,
      dataList: [],
      pagination: {
        // 分页参数
        pageNum: 0,
        pageSize: 10,
        total: 0,
      },

      point: '',
      typeList: [
        { id: '', name: '全部' },
        { id: '1', name: '已获取' },
        { id: '2', name: '已消耗' },
      ],

      affixIsTop: false,
    };
  },
  created() {
    getUserIntegral().then(res => {
      res = res || {};
      this.point = res.point || 0;
    });
  },
  methods: {
    onLoad() {
      this.pagination.pageNum++;
      let {
        searchForm,
        pagination: { pageNum: current, pageSize: size },
      } = this;

      return getUserIntegralFlowPage({
        ...searchForm,
        current,
        size,
      }).then(data => {
        this.dataPageLoadingSuccess();
        let { records: dataList = [], total = 0 } = data || {};
        this.dataList = [...this.dataList, ...dataList];
        this.pagination.total = total || 0;
        if (this.dataList.length >= this.pagination.total || !dataList.length)
          this.finished = true;
      });
    },
    onRefresh() {
      // 清空列表数据
      this.dataList = [];
      this.pagination.pageNum = 0;
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      return this.onLoad();
    },
    dataPageLoadingSuccess() {
      this.loading = false;
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
    },

    tagClick(type) {
      this.searchForm.type = type;
      this.onRefresh();

    },

  },
};
</script>

<style lang="less" scoped>
.total-integral {
  padding: 0 0 0 26px;
  height: 50px;
  line-height: 50px;
  font-size: 30px;
  color: @primary-color;
}

.card {
  margin: 0 14px;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;

  .type-select-container {
    padding: 16px;
    background-color: #fff;

    .van-tag {
      & + .van-tag {
        margin-left: 14px;
      }
    }
  }

  .integral-flow-item {
    position: relative;
    padding: 12px 16px;

    & + .integral-flow-item:after {
      content: '';
      position: absolute;
      z-index: 1;
      bottom: 0;
      top: 0;
      right: 16px;
      left: 16px;
      border-top: 1px solid #f1f1f1;
    }

    section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 4px 0;
      font-size: 15px;

      & > span {
        color: #333;
      }

      & > div {
        font-size: 16px;
        color: @primary-color;
      }
    }

    p {
      font-size: 12px;
      color: #999;
    }
  }
}
</style>
